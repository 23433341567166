import React from "react";
import Seo from "../components/Seo";
import Layout from "../components/layout";
import { graphql } from "gatsby";

import InstagramFeed from "../components/InstagramFeed";
import Trainers from "../components/Trainers";
import OurLocations from "../sections/OurLocations";
import { GatsbyImage } from "gatsby-plugin-image";
import Workouts from "../sections/Workouts";
import SanityPortableText from "../components/SanityPortableText/SanityPortableText";

const TrainerPage = ({ data }) => {
    const trainer = data && data.trainer;
    const meta_title = `${trainer.title} - Trainer - FEAT Outdoor Group Fitness Training, Bootcamp & Personal Training`;
    const first_name = trainer.title.split(" ")[0];
    const meta_description = `${first_name} runs fun community outdoor training sessions. Register today for your free week pass. Join ${first_name} at Sydney's leading outdoor group fitness training. Free week trial, no joining fees or contracts.`;

    return (
        <Layout>
            <Seo
                title={trainer.title}
                meta_title={meta_title}
                meta_desc={meta_description}
                slug={trainer.slug.current}
                image={trainer.smilePhoto}
            />
            <main>
                <div>
                    {/** Trainer Start */}
                    <div className="relative mt-10 2xl:mx-96 lg:mx-40 sm:mx-20">
                        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                            <div className="relative sm:py-16 lg:py-0">
                                <div
                                    aria-hidden="true"
                                    className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
                                >
                                    <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
                                    <svg
                                        className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                                        width={404}
                                        height={392}
                                        fill="none"
                                        viewBox="0 0 404 392"
                                    >
                                        <defs>
                                            <pattern
                                                id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                                                x={0}
                                                y={0}
                                                width={20}
                                                height={20}
                                                patternUnits="userSpaceOnUse"
                                            >
                                                <rect
                                                    x={0}
                                                    y={0}
                                                    width={4}
                                                    height={4}
                                                    className="text-gray-200"
                                                    fill="currentColor"
                                                />
                                            </pattern>
                                        </defs>
                                        <rect
                                            width={404}
                                            height={392}
                                            fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                                        />
                                    </svg>
                                </div>
                                <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                    {/* Testimonial card*/}
                                    <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                        <GatsbyImage // MainImg
                                            className="absolute inset-0 h-full w-full object-cover"
                                            image={
                                                trainer.smilePhoto.asset
                                                    .gatsbyImageData
                                            }
                                            alt={trainer.title}
                                        />

                                        <div className="relative px-8 h-40"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative mx-auto max-w-md px-4 mb-8 sm:max-w-3xl sm:px-6 lg:px-0">
                                {/* Content area */}
                                <div className="pt-12 sm:pt-16 lg:pt-20">
                                    <h1 className="font-heading uppercase text-3xl text-feat-darkgrey sm:text-4xl">
                                        {trainer.title}
                                    </h1>
                                    <div className="mt-6 text-gray-500 space-y-6 text-lg">
                                        {trainer._rawBody && (
                                            <SanityPortableText
                                                value={trainer._rawBody}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/** Trainer End */}

                    {/* Our Trainers */}
                    <div className="py-24 px-32 text-feat-darkgray bg-feat-lightgray">
                        <h2 className="text-heading uppercase text-4xl text-feat-darkgray text-center pb-16">
                            Our Trainers
                        </h2>
                        <div className="pt-8 text-center">
                            <Trainers excludeIds={[trainer.id]} />
                        </div>
                    </div>
                    {/* END Our Trainers */}

                    {/* Map and Location Details */}
                    <OurLocations />
                    {/* END Map and Location Details */}

                    <Workouts />

                    {/* Instagram Feed */}
                    <InstagramFeed className="bg-feat-blue-200" />
                    {/* End Instagram Feed */}
                </div>
            </main>
        </Layout>
    );
};

export default TrainerPage;

export const pageQuery = graphql`
    query SanityTrainerByID($id: String!) {
        trainer: sanityTrainer(id: { eq: $id }) {
            title
            slug {
                current
            }
            locations {
                location {
                    slug {
                        current
                    }
                }
            }
            funnyPhoto {
                asset {
                    gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                }
            }
            smilePhoto {
                asset {
                    gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                }
            }
            _rawBody(resolveReferences: { maxDepth: 5 })
        }
    }
`;
